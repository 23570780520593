import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { DawaColors } from "../../themes/Colors"
import media from "../../themes/media"
import Button from "../Button"

import howItWorks1 from "../../images/landing/what_we_do/Girl with Medication.jpg"
import howItWorks2 from "../../images/landing/what_we_do/ASCOVIME - Patient Consultation 2_2019.jpg"
import howItWorks3 from "../../images/landing/what_we_do/Medication in Hand.jpg"

const StyledWhatWeDoSection = styled.section`
  padding: 32px 10px;
  ${media.tablet`
    padding: 32px 150px 100px;
  `};
`
const SectionWrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
`
const ColWrapper = styled.div`
  ${media.tablet`
    margin-top: 100px;
  `};
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Header = styled.h3`
  font-size: 50px;
  font-weight: bold;
  color: ${DawaColors.black};
  text-align: center;
`
export const StyledLink = styled(Link)`
  color: ${DawaColors.white};
  font-size: 22px;
  text-decoration: none;
`
const Row = styled.div`
  ${media.tablet`
    display: flex;
  `};
`
const Col = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 12px;
  text-align: center;
  color: ${DawaColors.white};
  ${media.tablet`
    width: 33%;
    flex-direction: row;
    margin: 12px 35px;
  `};
  &:hover,
  :focus,
  :active {
    div {
      opacity: 0.8;
    }
  }
`
const Overlay = styled.div`
  position: absolute;
  padding: 24px;
  text-align: center;
  overflow-y: overlay;
  top: 44px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: white;
  color: black;
  font-size: 20px;
  font-size: 20px;
  font-weight: bold;
  p {
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
  }
`
const OverlayLabel = styled.div`
  font-size: 28px;
  color: ${DawaColors.black};
  text-align: center;
  margin-bottom: 20px;
  white-space: nowrap;
`
const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const WhatWeDo = () => {
  return (
    <StyledWhatWeDoSection>
      <SectionWrapper>
        <Header>What We Do</Header>
        <Row>
          <Col>
            <OverlayLabel>Care Coordination</OverlayLabel>
            <Image src={howItWorks1}></Image>
            <Overlay>
              <p>
                Our staff resolves scheduling issues by coordinating
                appointments between patients and doctors. We also prepare
                patients for their appointment to improve their experience
                during their visit. Patients will have clearer expectations
                before, during and after their appointments.
              </p>
            </Overlay>
          </Col>
          <Col>
            <OverlayLabel>Follow-Up Care</OverlayLabel>
            <Image src={howItWorks2}></Image>
            <Overlay>
              <p>
                We enable better communications via phone calls and text
                messaging between patients and their doctors/providers after a
                hospital visit. Your patients will now have a way of clarifying
                any medication, appointment or other health issues from wherever
                they are.
              </p>
            </Overlay>
          </Col>
          <Col>
            <OverlayLabel>Bespoke Health Education</OverlayLabel>
            <Image src={howItWorks3}></Image>
            <Overlay>
              <p>
                Your patients will have access to bespoke(personalized) health
                education material based on their specific condition especially
                pregnant mothers and patients with chronic conditions like
                diabetes and hypertension.
              </p>
            </Overlay>
          </Col>
        </Row>
        <ColWrapper>
          <Button
            type="primary"
            label={<StyledLink to="/contact-us">Request Demo</StyledLink>}
          />
        </ColWrapper>
      </SectionWrapper>
    </StyledWhatWeDoSection>
  )
}

export default WhatWeDo
