import React from "react"
import styled from "styled-components"
import { DawaColors } from "../../themes/Colors"

const PrimaryButton = styled.button`
  color: ${DawaColors.white};
  background-color: ${DawaColors.darkGrey};
  border: 1px solid ${DawaColors.darkGrey};
  border-radius: 35px;
  padding: 20px 70px;
  font-size: 22px;
  height: 70px;
  &:hover {
    background-color: ${props =>
      props.type === "primary" ? DawaColors.blue : DawaColors.white};
    color: ${props =>
      props.type === "primary" ? DawaColors.white : DawaColors.darkGrey};
    border: 1px solid
      ${props =>
        props.type === "primary" ? DawaColors.blue : DawaColors.white};
  }
`

const Button = props => {
  return (
    <PrimaryButton type={props.type} onClick={props.handleClick}>
      {props.label}
    </PrimaryButton>
  )
}

export default Button
